.card-search {
    display: flex; /* Enable flexbox */
    flex-direction: row; /* Stack children vertically */
    gap: 10px; /* Optional: space between each child */
    align-items: left;
    margin-top: 3vh;
    margin-left: 5vh;  
    width: 40%;
    background-color: aqua;
  }

  .card-details {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: left;
    margin-top: 2vh;
    /* margin-left: 1vh;  */
    margin-bottom: 2vh;
    background-color: red;
  }

  .image-inCard-search {
    margin: 2vh;
    height: 150px;
    width: 150px;
  }